<template>
  <div class="confirm-all">
    <div class="icon">
      <div @click="back()" class="left">&lt;返回</div>
      <h1>订单确认</h1>
    </div>
    <div class="confirm">
      <h2>订单信息</h2>
      <div class="up">
        <p>分类: {{ typeString[type] }}</p>
        <p>项目名称: {{ this.name }}</p>
        <p>购买数量: {{ this.nums }}</p>
        <p>付费金额: {{ this.money / 100 }}</p>
        <div class="up-beizhu">
          备注:
          <input type="text" placeholder="请输入备注信息" v-model="beizhu" />
        </div>
      </div>
      <!-- <div class="bottom">
        <p class="cancel" @click="cancel()">取消</p>
        <p @click="confirm()" class="pay">确认支付</p>
      </div> -->
    </div>
    <div class="bottom">
      <div class="money">
        <p class="moneyPay">支付金额:</p>
        <span>￥{{ money / 100 }}&nbsp;元</span>
      </div>
      <div class="quxiao" @click="cancel()">取消</div>
      <div class="zhifu" @click="confirm()">确认支付</div>
    </div>
  </div>
</template>

<script>
import { postlink } from "@/api/postapi.js";

export default {
  components: {},
  data() {
    return {
      sourceId: "",
      nums: "",
      type: "",
      name: "",
      money: "",
      beizhu: "",
      typeString: { 1: "活动", 2: "讲解", 3: "展览", 4: "物料" },
    };
  },
  methods: {
    back() {
      this.$router.go(-1);
    },
    cancel() {
      this.$router.go(-1);
    },
    confirm() {
      console.log(this.beizhu);
      this.$router.push("/paystatus");
      postlink("https://zmnh.bowu66.cn/h5/createOrder", {
        name: this.name,
        nums: this.nums,
        orderNo: this.orderNo,
        sourceId: this.sourceId,
        totalAmount: this.money,
        remark: this.beizhu,
        type: this.type,
      }).then((ok) => {
        if (typeof ok.data == "string") {
          document.write(ok.data);
        }
        console.log(ok);
        //   let newData = ok.data.data;
        // this.arr = newData
        // this.id=ok.data.data.id
        // console.log(this.id)
        //   this.arr = newData.map((item) => {
        //     item.select = false;
        //     return item;
      }).catch((err)=>{
        console.log(err)
      });
    },
  },
  mounted() {
    // console.log(this.$route.query.sourceId);
    console.log(this.$route.query.name);
    this.sourceId = this.$route.query.sourceId;
    this.nums = this.$route.query.nums;
    this.type = this.$route.query.type;
    this.name = this.$route.query.name;
    this.money = this.$route.query.money;
    this.orderNo = this.$route.query.orderNo;
    console.log(this.sourceId);
    console.log(this.nums);
    console.log(this.type);
    console.log(this.name);
    console.log(this.money);
    console.log(this.orderNo);
    console.log(this.beizhu);
  },
};
</script>

<style scoped>
.left {
  padding-left: 20px;
  font-size: 16px;
}
.confirm-all {
  background-color: #f4f4f4;
  height: 800px;
}
.confirm {
  /* margin-left: 0.2rem; */
  background-color: white;
  width: 3.8rem;
  height: 2.3rem;
  margin: auto;
  border-radius: 0.1rem;
}
.icon {
  width: 100%;
  height: 8vh;
  background-color: white;
  font-size: 0.3rem;
  margin: auto;
  text-align: center;
  line-height: 8vh;
  margin-bottom: 0.2rem;
  display: flex;
}
h1 {
  font-size: 0.2rem;
  margin-left: 90px;
}
h2 {
  font-size: 0.2rem;
  color: black;
  margin-left: 0.1rem;
  padding-top: 0.2rem;
}
h4 {
  font-size: 0.2rem;
}
p {
  font-size: 0.18rem;
  margin-top: 0.1rem;
}
.up-beizhu {
  font-size: 0.18rem;
  margin-top: 0.1rem;
}
input {
  outline: none;
  width: 270px;
  font-size: 0.18rem;
  border: none;
}

.up {
  width: 95%;
  height: 4rem;
  /* border: 0.02rem solid black; */
  margin-top: 0.1rem;
  border-radius: 0.1rem;
  margin-left: 0.28rem;
}
.money {
  height: 6vh;
  border-radius: 0.05rem;
  margin: auto;
  margin-top: 0.1rem;
  font-size: 0.16rem;
  display: flex;
  line-height: 8vh;
  justify-content: space-around;
  text-indent: 0;
  background-color: white;
  line-height: 6vh;
  margin-left: 0.2rem;
}
.moneyPay {
  width: 1rem;
  line-height: 3vh;
}
.money span {
  font-size: 0.24rem;
  line-height: 6vh;
  /* text-align: right; */
}

.zhifu {
  margin-top: 2vh;
  width: 40%;
  background-color: #6fb6b6;
  border-radius: 0.2rem;
  height: 5vh;
  font-size: 0.16rem;
  /* margin-left: 0.2rem; */
  text-align: center;
  line-height: 5vh;
  color: white;
  font-family: "宋体";
  /* display: flex; */
  /* line-height: 5px; */
  margin-right: 0.1rem;
}
.bottom {
  width: 100%;
  height: 8vh;
  position: fixed;
  bottom: 0;
  display: flex;
  background-color: white;
  justify-content: space-between;
}
.bottom span {
  color: #ff7e0c;
}
.quxiao {
  width: 25%;
  height: 5vh;
  border-radius: 0.05rem;
  font-size: 0.16rem;
  /* border: yellow 1px solid; */
  line-height: 5vh;
  font-family: "宋体";
  margin-left: 0.1rem;
  margin-top: 2vh;
  text-align: center;
  color: #6fb6b6;
}
</style>